import {pegaDados, processaArquivo} from "../../services/GenericAPI";
let url = 'controle_acesso'
const state = {
   allRotinas:[],
   permissao: JSON.parse(sessionStorage.getItem('permissao')) || {inserir:false,alterar:false,exlcuir:false},
   lista_dash: [
      {item: {titulo_item: 'Cadastro', icon:'mdi-view-dashboard-edit-outline', grupo: []}},
   ],
}
const getters = {
   listaDash: (state) => {
      return state.lista_dash
   },
   getSelecionado: state => {
      return state.selecionado
   },
   getPermissao: state => {
      return state.permissao
   },
   listAllRotinas: state => {
      return state.allRotinas
   },
}
const actions = {
   async printPdf({commit}, {link,filtro,onSucesso, onErro}) {
      try {
         const list = (await processaArquivo(link, filtro))
         onSucesso(list)
      } catch (error) {
         console.log('Erro',error)
         onErro()
      }
   },

  setPermissao({commit}, valor) {
      commit('SET_PERMISSAO', valor)
   },

  setSelecionado({commit}, valor) {
     commit('SET_SELECIONADO', valor)
   },

   async getAllRotinas ({ commit },{onSucesso,onErro}) {
      try {
         const list = (await pegaDados(url+'/allRotinas', state.filtro)).data;
         commit('SET_ALL_ROTINAS', list);
         onSucesso()
      } catch (error) {
         onErro()
      }
   },

   async getListaDash({commit}, {onSucesso, onErro}) {
      try {
         var filtro = {grupo_acesso_id: sessionStorage.getItem('grupo_acesso_id')}
         const list = (await pegaDados(url + '/lista_dash', filtro)).data
         commit('SET_LISTA_DASH', list.data)
         onSucesso(list.acesso_auditoria)
      } catch (error) {
         console.log(error);
         onErro()
      }
   },


}
const mutations = {
   SET_SELECIONADO(state, payload) {
      sessionStorage.setItem('controle_acesso_id', payload)
      state.selecionado = payload
   },
   SET_ALL_ROTINAS(state, payload) {
      state.allRotinas = payload
   },
   SET_PERMISSAO(state, payload) {
      sessionStorage.setItem('permissao',JSON.stringify(payload))
      state.permissao= payload
   },
   SET_LISTA_DASH(state, lista) {
      state.lista_dash = lista
   }
}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
