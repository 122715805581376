/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {CentroCusto} from '../../models/centro_custo'
import {CentroCustoSCH, getCentroCustoSCH} from "../../search/CentroCustoSCH";
import {ContaSCH} from "../../search/ContaSCH";

const url = '/centro_custo'

const state = {
  all: [],
  centro_custo_table: [],
  allList: [],
  centro_custo: new CentroCusto(),
  filtro: new Filtro()
}

const getters = {
  listaAllCentroCustos: (state) => {
    return state.allList
  },

  listaCentroCustos: (state) => {
    return state.all
  },
  pegaCentroCusto: (state) => {
    return state.centro_custo
  },
  pegaCentroCustoTable: (state) => {
    return state.centro_custo_table
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['descricao'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {centro_custos_id, onSucesso,onErro}) {
    try {
      var filtro = getCentroCustoSCH('centro_custo_id', centro_custos_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_CENTRO_CUSTO', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CentroCustoSCH(perPage))
  },

  async setAllListCentroCustos({commit},{onSucesso, onErro}) {
    try {
      var filtro = CentroCustoSCH('*')
      const list = (await pegaDados(url+'/get', filtro)).data

      commit('SET_ALL_LIST', list.data)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
    }
    },
  async setAllCentroCustos({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_CENTRO_CUSTOS', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarCentroCusto({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.centro_custo, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setCentroCusto({commit}, centro_custo) {
    commit('SET_CENTRO_CUSTO', centro_custo)
  },
  setCentroCustoTable({commit}, centro_custo_table) {
    commit('SET_CENTRO_CUSTO_TABLE', centro_custo_table)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_CENTRO_CUSTOS(state, centro_custos) {
    state.all = centro_custos
  },
  SET_CENTRO_CUSTO(state, centro_custo) {
    state.centro_custo = centro_custo
  },
  SET_CENTRO_CUSTO_TABLE(state, centro_custo_table) {
    state.centro_custo_table = centro_custo_table
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
