/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getCaixaSCH = (campo, valor, condicao)=>{
   let filtro = new Filtro();
   filtro.perPage = 1;
   filtro.filters.addFilter(campo,valor,condicao);
   filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
   filtro.fields = ['*'];
   return filtro
};
export const  getMovCaixaSCH = (campo, valor, condicao)=>{
   let filtro = new Filtro();
   filtro.perPage = 1;
   filtro.filters.addFilter(campo,valor,condicao);
   // filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
   filtro.fields = ['*'];
   return filtro
};
export const CaixaSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['data','status','contas.descricao','caixas.conta_id']
   filtro.filters.addFilter('caixas.empresa_id',sessionStorage.getItem('empresa_id'),'=')
   filtro.join.addLeft('contas','contas.conta_id','caixas.conta_id')
   filtro.addOrder('data','desc')

   var str = JSON.parse(sessionStorage.getItem('usuario_contas'))
   if (str.length > 0) {
      var ar = []
      for(var i=0;i<str.length; i++){
         ar.push("\'" +str[i]+"\'")
      }
      filtro.filters.addFilterRaw(" caixas.conta_id in("+ar+")")
   }

   return filtro
}
