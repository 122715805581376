/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {ContaPagar} from '../../models/conta_pagar'
import {ContaPagarSCH, getContaPagarSCH} from "../../search/ContaPagarSCH";

const url = '/conta_pagar'

const state = {
  editando_cp: false || sessionStorage.getItem('editando_cp'),
  all: [],
  allList: [],
  conta_pagar: new ContaPagar(),
  filtro: new Filtro()
}

const getters = {
  listaAllContaPagar: (state) => {
    return state.allList
  },

  listaContaPagar: (state) => {
    return state.all
  },
  pegaContaPagar: (state) => {
    return state.conta_pagar
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  pegaEditando_cp: (state) => {
    return state.editando_cp
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['razao','fantasia','complemento','conta', 'evento','documento','ativo'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {id, onSucesso,onErro}) {
    try {
      // console.log('getItem Conta Pagar: ', id);
      var filtro = getContaPagarSCH('conta_pagar_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      commit('SET_CONTA_PAGAR', list.data)
      if (list.data[0] !== undefined) {
        list.data[0].valor = list.data[0].vl_total
        commit('SET_CONTA_PAGAR', list.data[0])
        onSucesso(list.data[0])
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ContaPagarSCH(perPage))
  },

  async setAllListContaPagar({commit},{onSucesso, onErro}) {
    try {
      const list = (await lista(url + '/all')).data
      commit('SET_ALL_LIST', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
    }
},
  async setAllContaPagar({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get_view', state.filtro)).data
      commit('SET_CONTAS_PAGAR', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async getViewTotal({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get_view_total', state.filtro)).data
      onSucesso(list)
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarBaixa({commit}, {item,onSucesso,onErro}) {
    try {
      console.log('processarBaixa');
      let res = await processar(url + '/baixar', item, 'gravar')
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async processarEstorno({commit}, {item,onSucesso,onErro}) {
    try {
      let res = await processar(url + '/estornar', item, 'gravar')
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async processarContaPagar({commit}, {acao,onSucesso,onErro}) {
    try {
      // console.log('processarContaPagar: ', state.conta_pagar);
      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.conta_pagar, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async processarContaPagarParcela({commit}, {item,acao,onSucesso,onErro}) {
    try {
      console.log('processarContaPagarParcela: ',JSON.parse(JSON.stringify(item)));
      let res = await processar(url + (acao === 'gravar' ? '/commit_parcela' : '/delete_parcela'), item, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },

  setContaPagar({commit}, conta_pagar) {
    commit('SET_CONTA_PAGAR', conta_pagar)
  },
  setEditandoCP({commit}, editando) {
    commit('SET_EDITANDO_CP', editando)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_CONTAS_PAGAR(state, caixas) {
    state.all = caixas
  },
  SET_CONTA_PAGAR(state, conta_pagar) {
    conta_pagar.valor = conta_pagar.vl_total
    state.conta_pagar = conta_pagar
  },
  SET_EDITANDO_CP(state, editando_cp) {
    sessionStorage.setItem('editando_cp',editando_cp)
    state.editando_cp = editando_cp
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
