/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getContaPagarSCH = (campo, valor, condicao)=>{
   let filtro = new Filtro();
   filtro.perPage = 1;
   filtro.filters.addFilter(campo,valor,condicao);
   filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
   filtro.fields = ['*'];
   return filtro
};
export const ContaPagarSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['*']
   filtro.addOrder('dt_baixa','desc')
   filtro.addOrder('dt_vencimento','asc')

   // filtro.addOrder('ordem','asc')
   // filtro.filters.addFilter('is_pago', false, '=')
   // filtro.addOrder('is_vencido','desc')

   var str = JSON.parse(sessionStorage.getItem('usuario_contas'))
   if (str.length > 0) {
      var ar = []
      for(var i=0;i<str.length; i++){
         ar.push("\'" +str[i]+"\'")
      }
      filtro.filters.addFilterRaw(" conta_id in("+ar+")")
   }


   return filtro
}
// export const ContaPagarSCH = (perPage) => {
//    var filtro = new Filtro()
//    filtro.perPage = perPage
//    filtro.fields = ['dt_emissao','fornecedores.razao','vl_total','documento']
//    filtro.join.addLeft('fornecedores','fornecedores.fornecedor_id','contas_pagar.fornecedor_id')
//    filtro.filters.addFilter('contas_pagar.empresa_id',sessionStorage.getItem('empresa_id'),'=')
//    filtro.addOrder('dt_emissao','desc')
//    return filtro
// }
