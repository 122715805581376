/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getEventoCaixaSCH = (campo, valor, condicao)=>{
   let filtro = new Filtro();
   filtro.perPage = 1;
   filtro.filters.addFilter(campo,valor,condicao);
   filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
   filtro.fields = ['*'];
   return filtro
};
export const EventoCaixaSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['eventos_caixa.descricao','tipo','eventos_caixa.conta_id','contas.descricao as conta']
   filtro.addOrder('eventos_caixa.descricao','asc')
   filtro.filters.addFilter('eventos_caixa.empresa_id',sessionStorage.getItem('empresa_id'),'=')
   filtro.join.addLeft('contas','contas.conta_id','eventos_caixa.conta_id')
   var str = JSON.parse(sessionStorage.getItem('usuario_contas'))
   if (str.length > 0) {
      var ar = []
      for(var i=0;i<str.length; i++){
         ar.push("\'" +str[i]+"\'")
      }
      filtro.filters.addFilterRaw(" eventos_caixa.conta_id in("+ar+")")
   }

   return filtro
}
