import {Filtro} from "../models/filtro/filtro";

export const FornecedorSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  // filtro.fields = ['*'];
   filtro.fields = ['razao','fantasia','cnpj','fornecedores.evento_caixa_id','descricao', 'fornecedores.empresa_id','fone1','cidade','uf'];
   filtro.addOrder('razao','asc')
   filtro.filters.addFilter('fornecedores.empresa_id',sessionStorage.getItem('empresa_id'),'=')
   filtro.join.addLeft('eventos_caixa','eventos_caixa.evento_caixa_id','fornecedores.evento_caixa_id')

  return filtro
};

export const AllFornecedorSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['razao','fantasia','cnpj','evento_caixa_id'];
  filtro.addOrder('razao','asc')
   filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')

  return filtro
};
