/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Usuario {
  constructor () {
    this.usuario_id =''
    this.nome = ''
    this.empresa_id = sessionStorage.getItem('empresa_id')
    this.grupo_acesso_id = ''
    this.senha = ''
    this.usuario_contas = []
  }
}
