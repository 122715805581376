/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Empresa {
  constructor (empresa_id) {
    this.empresa_id = uuidV4()
    this.cnpj = ''
    this.ie= ''
    this.razao = ''
    this.fantasia = ''
    this.logrado = '' // endereco + numero
    this.complemento = ''
    this.bairro = ''
    this.cidade = ''
    this.uf = ''
    this.cep = ''
    this.login_id = ''
    this.fone1 = ''
    this.fone2 = ''
    // this.contas_pagar_evento_caixa_id = ''



  }
}
