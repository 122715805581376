/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {CentroAtivoRecurso} from '../../models/centro_ativo_recurso'
import {CentroAtivoRecursoSCH, getCentroAtivoRecursoSCH} from "../../search/CentroAtivoRecursoSCH";
import {ContaSCH} from "../../search/ContaSCH";

const url = '/centro_ativo_recurso'

const state = {
  all: [],
  allList: [],
  centro_ativo_recurso: new CentroAtivoRecurso(),
  filtro: new Filtro()
}

const getters = {
  listaAllCentroAtivoRecursos: (state) => {
    return state.allList
  },

  listaCentroAtivoRecursos: (state) => {
    return state.all
  },
  pegaCentroAtivoRecurso: (state) => {
    return state.centro_ativo_recurso
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['descricao'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {centro_ativo_recursos_id, onSucesso,onErro}) {
    try {
      var filtro = getCentroAtivoRecursoSCH('centro_ativo_recurso_id', centro_ativo_recursos_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_CENTRO_ATIVO', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CentroAtivoRecursoSCH(perPage))
  },

  async setAllListCentroAtivoRecursos({commit},{onSucesso, onErro}) {
    try {
      var filtro = CentroAtivoRecursoSCH('*')
      const list = (await pegaDados(url+'/get', filtro)).data

      commit('SET_ALL_LIST', list.data)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
    }
    },
  async setAllCentroAtivoRecursos({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_CENTRO_ATIVOS', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarCentroAtivoRecurso({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.centro_ativo_recurso, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setCentroAtivoRecurso({commit}, centro_ativo_recurso) {
    commit('SET_CENTRO_ATIVO', centro_ativo_recurso)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_CENTRO_ATIVOS(state, centro_ativo_recursos) {
    state.all = centro_ativo_recursos
  },
  SET_CENTRO_ATIVO(state, centro_ativo_recurso) {
    state.centro_ativo_recurso = centro_ativo_recurso
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
