/* eslint-disable */
import {autentica, lista, nao_autorizado, pegaDados, processar, setarToken} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {Usuario} from '../../models/usuario'
import {BaseDescSCH, getBaseSCH} from "../../search/BaseSCH";
import {UsuarioSCH} from "../../search/UsuarioSCH";

const url = '/usuarios'

const state = {
   all: [],
   allList: [],
   usuario: new Usuario(),
   filtro: new Filtro()
}

const getters = {
   listaAllUsuarios: (state) => {
      return state.allList
   },

   listaUsuarios: (state) => {
      return state.all
   },
   pegaUsuario: (state) => {
      return state.usuario
   },
   pegaFiltro: (state) => {
      return state.filtro
   }
}

const actions = {
   searchItem({commit}, search) {
      state.filtro.filters.search = [];
      state.filtro.filters.addSearch(['nome','usuario_id','descricao'], '%' + search + '%', 'ilike')
   },
   async getItem({commit}, {usuario_id, onSucesso,onErro}) {
      try {
         var filtro = getBaseSCH('usuario_id', usuario_id, '=')
         const list = (await pegaDados(url+'/get', filtro)).data
         if (list.data[0] !== undefined) {
            commit('SET_USUARIO', list.data[0])
            onSucesso()
         } else {
            onErro()
         }
      } catch (error) {
         onErro()
         console.log(error)
      }
   },

   setFiltro({commit}, perPage) {
      commit('SET_FILTRO', UsuarioSCH(perPage))
   },

   async setAllListUsuarios({commit}) {
      const list = (await lista(url + '/all')).data
      commit('SET_ALL_LIST', list)
   },
   async setAllUsuarios({commit},{onSucesso, onErro}) {
      try {
         const list = (await pegaDados(url + '/get', state.filtro)).data
         commit('SET_USUARIOS', list)
         onSucesso()
      } catch (error) {
         console.log(error)
         onErro()
         nao_autorizado(error)
      }
   },
   async processarUsuario({commit}, {acao,onSucesso,onErro}) {
      try {
         let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.usuario, acao)

         this.dispatch('geral/setMsgSuccess', res.data.message)
         onSucesso()
      } catch (error) {
         onErro()
         console.log('error: ',error)
         this.dispatch('geral/setObjMsgError', error.response.data)
      }
   },
   async autenticaUsuarios({commit}, {usuario, onSucesso, onErro}) {
      try {
         const res = (await autentica(url+'/auth', usuario))

         sessionStorage.setItem('usuario_contas', JSON.stringify(res.data.usuario.usuario_contas.map(item => item.conta_id)))
         sessionStorage.setItem('usuario', res.data.usuario.nome)
         sessionStorage.setItem('grupo_acesso_descricao', res.data.usuario.grupo_acesso_descricao)
         sessionStorage.setItem('empresa', res.data.usuario.empresa!== undefined ?  res.data.usuario.empresa : '')
         sessionStorage.setItem('usuario_id', res.data.usuario.usuario_id)
         sessionStorage.setItem('empresa_id', res.data.usuario.empresa_id)
         sessionStorage.setItem('grupo_acesso_id', res.data.usuario.grupo_acesso_id)
         sessionStorage.setItem('is_admin', res.data.usuario.is_admin)

         setarToken(res.data.token, res.data.usuario.usuario_id)
         sessionStorage.setItem('token', res.data.token)
         sessionStorage.setItem('token_expiration', res.data.token_expiration * 60 * 1000)

         this.dispatch('token/cancelarToken')
         this.dispatch('token/start', null)
         onSucesso();
      } catch (error) {
         console.log(error);
         this.dispatch('geral/setObjMsgError', error.response.data)
         onErro()
      }
   },
   setUsuario({commit}, usuario) {
      commit('SET_USUARIO', usuario)
   },
}

const mutations = {

   SET_ALL_LIST(state, filtro) {
      state.allList = filtro
   },
   SET_FILTRO(state, filtro) {
      state.filtro = filtro
   },
   SET_USUARIOS(state, usuarios) {
      state.all = usuarios
   },
   SET_USUARIO(state, usuario) {
      state.usuario = usuario
   },

}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
