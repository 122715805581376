import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import {sync} from 'vuex-router-sync'
import './assets/css/main.scss'
import 'vuetify/styles'
import 'aos/dist/aos.css'
import "vue3-json-viewer/dist/index.css";
import '@mdi/font/css/materialdesignicons.css'
import ModalCadastro from './components/ModalCadastro'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {createVuetify} from 'vuetify'
import PForm from "./components/PForm";
import TableIterator from "./components/TableIterator";
import VueTheMask  from 'vue-the-mask'
import Alerta from "./components/Alerta";
import VCurrencyText from "v-currency-text";
import ConfirmPopup from "./components/ConfirmPopup";
// import VCascadeSelect from "./components/VCascadeSelect";
import VCascadeSelect from 'v-cascade-select';
import money3 from 'v-money3'

const vuetify = createVuetify({
   components,
   directives,
   theme: {
      defaultTheme: 'dark', // Define o tema escuro como padrão
      themes: {
         dark: {
            dark: true,
            colors: {
               surface: '#181A20', // Cor de superfície dos cards
               amarelo: '#F0B90B',
               // amarelo_dark: '#cfa00a',
               verde: '#0ECB81',
               vermelho: '#F6465A',
               // verde: '#4CAF50',
               // background: 'red', // Cor de fundo
               // primary: '#BB86FC', // Cor primária
               // secondary: '#03DAC6', // Cor secundária
               // error: '#CF6679', // Cor de erro
               // e outras cores que você queira personalizar
            },
         },
      },
   },

})



const app = createApp(App)
sync(store, router)
app.use(money3)

app.use(router)
app.use(vuetify)
app.use(store)
app.use(VueTheMask)
app.component('v-cascade-select', VCascadeSelect)
app.component('v-currency-text',VCurrencyText)
app.component('pro-modal', ModalCadastro)
app.component('p-form', PForm)
app.component('confirm-popup', ConfirmPopup)
app.component('pro-alerta', Alerta)
app.component('table-iterator', TableIterator)
app.mount('#app')
