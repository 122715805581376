/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getGrupoAcessoSCH = (campo, valor, condicao)=>{
   let filtro = new Filtro();
   filtro.perPage = 1;
   filtro.filters.addFilter(campo,valor,condicao);
   filtro.fields = ['*'];
   return filtro
};
export const GrupoAcessoSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['*']
   filtro.addOrder('descricao','asc')
   return filtro
}
