<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
			<div :class="'table_iterator ' + classe " id="tabInter">
						<v-row justify="end">
									<v-col v-if="showBtnTop" cols="12" sm="3" md="2" lg="1" :style="'padding-top:' + (!showBtnTop ? '0!important;' : 'auto' )">
												<v-btn size="small"  v-if="showAddButton" @click="goToAdd" :color="cor_novo" :disabled="disabledAddButton"
																			prepend-icon="mdi-plus" name="novo"><span > {{txt_novo}}</span>
												</v-btn>
									</v-col>
									<v-col cols="12" style="padding-top: 1!important;">
												<v-progress-linear v-if="loading" indeterminate color="red"></v-progress-linear>
												<v-table fixed-header sortable density="compact" class="tabela">
															{{listaCabecalho}}
															<thead>
															<tr>
																		<th v-for="(item, i) in lista_cab" :class="'v_tr text-'+item.align" scope="col"
																						:aria-sort="item.aria_sort" :id="'th_'+i" @click="ordenando(item,index)">
																					<div
																							:style="'padding-left: 0;padding-right: 0; justify-content:'  +item.align + '; cursor:' + (item.sortable ? 'pointer' : 'inherit')">
																								<span style="text-transform: uppercase; font-weight: bold;font-size: 12px">{{item.text}}</span>
																								<v-icon aria-label="ordenar" v-if="item.icone !== 'mdi-nada'" size="small">{{item.icone}}</v-icon>
																					</div>
																		</th>
															</tr>
															</thead>
															<tbody class="linhas">
															<tr v-for="item in itens" :key="item.name" class="hover_row">
																		<td v-for="(n, index) in cabecalho" :key="'index'+index" :class="'text-'+n.align" :style="'width:'+n.width">
																					<slot v-if="n.value !=='actions'" :name="n.slot" v-bind:item="item"></slot>
																					<slot v-else name="acao" v-bind:item="item" ></slot>
																					<slot name="expander" v-bind:item="item"></slot>
																		</td>
															</tr>
															<tr v-if="itens.length <=0">
																		<td colspan="100%">
																					<v-alert density="compact" color="deep-orange" icon="mdi-alert" title="Nada há exibir no momento"
																														variant="outlined"></v-alert>
																		</td>
															</tr>
															</tbody>
															<tfoot>
															<tr v-if="showfooterextra">
																		<slot name="extra_tr"></slot>
															</tr>
															<tr>
																		<td colspan="100%" style="padding: 0">
																					<v-container fluid style="padding: 10px 15px ">
																								<div >
																											<v-row no-gutters justify="end" style="padding-left: 0; padding-right: 0">
																														<v-col cols="12">
																																	<slot name="extra"></slot>
																														</v-col>
																														<v-col cols="12" sm="3" md="2" lg="1" class="footer">
																																	<v-select persistent-hint density="compact" v-model="select" hint="itens por pagina" single-line
																																											:items="[10,15,30,60,100]" class="inp " style="width: 150px; margin-right: 1%">
																																				<!--<template v-slot:prepend>-->
																																							<!--<small style="font-size: 12px">itens por pagina</small>-->
																																				<!--</template>-->
																																	</v-select>
																														</v-col>
																														<v-col cols="12" sm="6" md="4" lg="4" class="footer" >
																																	<v-pagination v-model="page" :length="totalPaginas" :total-visible="7" size="small"
																																															density="compact" active-color="amarelo" variant="flat"/>
																														</v-col>
																														<v-col cols="12" sm="3" md="2" lg="1" v-if="!showBtnTop">
																																	<v-btn size="small"  v-if="showAddButton" @click="goToAdd" color="verde" :disabled="disabledAddButton"
																																								prepend-icon="mdi-plus" name="novo">{{txt_novo}}
																																	</v-btn>
																														</v-col>
																											</v-row>
																								</div>
																					</v-container>
																		</td>
															</tr>
															</tfoot>
												</v-table>
									</v-col>
						</v-row>
						
			</div>

</template>

<script>
   /* eslint-disable */
   import {EventBus} from '../helpers/event-bus'
   import {mapGetters} from 'vuex'
   import paginacao from '../helpers/paginacao'

   export default {
      mixins: [paginacao],
      name: 'TableIterator',
      props: {
         cabecalho: {
            type: Array,
            default: () => []
         },
         itens: {
            type: Array,
            default: () => []
         },
         id: {default: 'tabela'},
         classe: {default: ''},
         txt_novo: {default: 'Novo'},
         cor_novo: {default: 'verde'},
         PorPage: {default: 15},
         totalPaginas: {default: 1},
         setPagina: {default: 1},
         showItemPerPag: {
            type: Boolean,
            default: true
         },
									showBtnTop: {
            type: Boolean,
            default: true
         },
         dense: {
            type: Boolean,
            default: false
         },
         showfooterextra: {
            type: Boolean,
            default: false
         },
         chave: {default: ''},
         hasheader: {
            type: Boolean,
            default: false
         },
         hideFooter: {
            type: Boolean,
            default: true
         },
         showAddButton: {
            type: Boolean,
            default: true
         },
         showContextMenu: {
            type: Boolean,
            default: false
         },
         disabledAddButton: {
            type: Boolean,
            default: false
         },
         pagSiple: {
            type: Boolean,
            default: false
         },
      },
      data: () => ({
         pageCount: 0,
         lista_cab: [],
         expanded: [],
         exp: '',
         loading: true,
         loadingProgress: true,
         singleExpand: false,
         select: 15,
         page: 1,
         sortBy: '',
         sortDesc: false,
         itensPerPage: 15,

         
      }),
      methods: {
         validarCampo(campo) {
            if (campo !== undefined && campo !== null && campo !== '') {
               return true
            } else {
               return false
            }
         },
         isSortable(item) {
            if (item.hasOwnProperty('sortable')) {
               return item.sortable
            } else {
               return true
            }
         },
         setLoadingColor(cor) {
            return cor
         },
         setClasse(item) {
            var classe = 'text-left '

            if (this.validarCampo(item.align)) {
               classe = 'text-' + item.align + ' '
            }

            if (item.hasOwnProperty('sortable')) {
               classe += item.sortable ? 'sortable ' : ' '
            } else {
               classe += 'sortable '
            }
            return classe
         },
         ordenando(item, index) {
            if (this.isSortable(item)) {
               this.loading = true
               if (item.aria_sort === 'none') {
                  item.aria_sort = 'ascending'
                  item.icone = 'mdi-menu-up'

               } else if (item.aria_sort === 'ascending') {
                  item.aria_sort = 'descending'
                  item.icone = 'mdi-menu-down'
               } else if (item.aria_sort === 'descending') {
                  item.aria_sort = 'none'
                  item.icone = 'mdi-nada'
               }
            }
            this.lista_cab[index] = item

            this.ordenar(item.value, item.aria_sort)
         },

         goToAdd() {
            this.$emit('novo')
         },
         updateItemsPerPage(number) {
            this.itensPerPage = number
            this.$emit('por_paginas', parseInt(number))
         },
         ordenar(id, ordem) {

            var direcao = 'asc'

            if (!this.header) {
               if (ordem !== 'none' && ordem !== null) {
                  direcao = ordem === 'ascending' ? 'asc' : 'desc'
                  var ordenar = {
                     campo: id,
                     direction: direcao
                  }
                  this.$emit('ordem', ordenar)
               }
            } else {
               var orde = {
                  campo: '',
                  direction: ''
               }

               if (ordem !== 'none' && ordem !== null) {
                  direcao = ordem === 'ascending' ? 'asc' : 'desc'
                  orde = {
                     campo: id,
                     direction: direcao
                  }
                  this.$emit('ordem', orde)
               } else {
                  orde = {
                     campo: id,
                     direction: 'asc'
                  }
                  this.$emit('ordem', orde)
               }
            }
         },
      },
      computed: {
         listaCabecalho() {
            var cabecalho = JSON.parse(JSON.stringify(this.cabecalho))
            for (var i = 0; i < cabecalho.length; i++) {
               cabecalho[i].classe = this.setClasse(cabecalho[i])
               cabecalho[i].icone = 'mdi-nada'
               cabecalho[i].aria_sort = 'none'
            }
            this.lista_cab = cabecalho
         },

         getTotalPagina() {
            return Math.ceil(this.itens.length / this.select)
         },
         ...mapGetters('geral', {search: 'getSearch'}),
      },
      watch: {
         select: function () {
            this.select = parseInt(this.select) <= 0 ? 1 : this.select
            this.page = 1
            if (!this.pagSiple) {
               if (this.select > 0) {
                  this.updateItemsPerPage(parseInt(this.select))
               } else {
                  this.updateItemsPerPage(5)
               }
            }
         },
         setPagina: function () {
            if (this.setPagina) {
               this.page = 1
            }
         },

         page: function () {
            this.page = isNaN(this.page) ? 0 : this.page
            if (this.hideFooter) {
               this.loading = true
            }
            if (!this.pagSiple) {
               this.$emit('paginas', parseInt(this.page))
            } else {
            }
         },
      },
      created() {
      },
      mounted() {
         if (!isNaN(this.PorPage)) {
            this.select = this.PorPage
         }
         
         var _this = this
         window.document.addEventListener('keydown', function fn(e) {
            e = e || window.event
            const code = e.which || e.keyCode
            if (code === 45) {
               _this.$emit('novo')
               e.preventDefault()
            }
         })

         EventBus.$on('SHOW_LISTA', (sucesso) => {
            this.loading = false
            this.loadingProgress = false
												
         })

      },
      beforeDestroy() {
         EventBus.$off('SHOW_LISTA')
      },

   }
</script>
<style lang="scss">
			.v-table--density-compact {
						--v-table-header-height: 35px !important; //40
						--v-table-row-height: 32px !important; //36
			}
			
			.footer {
						.v-field__input {
									font-size: 12px !important;
									min-height: 25px !important;
						}
						
						.v-messages__message {
									font-size: 11px;
						}
			}
			
			.v-input__control {
						/*font-size: 5px !important;*/
			}
			
			.table_iterator {
						font-size: 12px;
						text-transform: uppercase;
						/*.v-table > .v-table__wrapper > table > tbody > tr > td{*/
						.v-table > .v-table__wrapper > table > tbody > tr > td,
						.v-table > .v-table__wrapper > table > tbody > tr > th,
						.v-table > .v-table__wrapper > table > thead > tr > td,
						.v-table > .v-table__wrapper > table > thead > tr > th,
						.v-table > .v-table__wrapper > table > tfoot > tr > td,
						.v-table > .v-table__wrapper > table > tfoot > tr > th {
									padding: 0 10px!important;
						}
						
			}
			
			/*.somatorio{*/
						/*.v-table .v-table__wrapper > table > tfoot > tr > td,*/
						/*.v-table .v-table__wrapper > table > tfoot > tr > th {*/
									/*border-top: none;*/
						/*}*/
			/*}*/
			
			.somatorioIt{
						.v-table .v-table__wrapper > table > tfoot > tr > td,
						.v-table .v-table__wrapper > table > tfoot > tr > th {
									border-top: none;
						}

			}
	
</style>


