/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {Conta} from '../../models/conta'
import {BaseDescSCH, getBaseSCH} from "../../search/BaseSCH";
import {CentroCustoSCH} from "../../search/CentroCustoSCH";
import {ContaSCH, getContaSCH} from "../../search/ContaSCH";

const url = '/contas'

const state = {
   all: [],
   allList: [],
   conta: new Conta(),
   filtro: new Filtro()
}

const getters = {
   listaAllContas: (state) => {
      return state.allList
   },

   listaContas: (state) => {
      return state.all
   },
   pegaConta: (state) => {
      return state.conta
   },
   pegaFiltro: (state) => {
      return state.filtro
   }
}

const actions = {
   searchItem({commit}, search) {
      state.filtro.filters.search = [];
      state.filtro.filters.addSearch(['descricao'], '%' + search + '%', 'ilike')
   },
   async getItem({commit}, {contas_id, onSucesso, onErro}) {
      try {
         var filtro = getContaSCH('conta_id', contas_id, '=')
         const list = (await pegaDados(url + '/get', filtro)).data
         if (list.data[0] !== undefined) {
            commit('SET_CONTA', list.data[0])
            onSucesso()
         } else {
            onErro()
         }
      } catch (error) {
         onErro()
         console.log(error)
      }
   },

   setFiltro({commit}, perPage) {
      commit('SET_FILTRO', ContaSCH(perPage))
   },

   async setAllListContas({commit}, {onSucesso, onErro}) {
      try {
         var filtro = ContaSCH('*')
         const list = (await pegaDados(url + '/get', filtro)).data
         commit('SET_ALL_LIST', list.data)
         onSucesso()
      } catch (error) {
         console.log(error)
         onErro()
      }
   },
   async setTotdasContas({commit}, {onSucesso, onErro}) {
      try {
         var filtro = ContaSCH('*')
         const list = (await pegaDados(url + '/get', filtro)).data
         commit('SET_TODAS_CONTAS', list.data)
         onSucesso()
      } catch (error) {
         console.log(error)
         onErro()
      }
   },
   async setAllContas({commit}, {onSucesso, onErro}) {
      try {
         const list = (await pegaDados(url + '/get', state.filtro)).data
         commit('SET_CONTAS', list)
         onSucesso()
      } catch (error) {
         console.log(error)
         onErro()
         nao_autorizado(error)
      }
   },
   async processarConta({commit}, {acao, onSucesso, onErro}) {
      try {
         let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.conta, acao)

         this.dispatch('geral/setMsgSuccess', res.data.message)
         onSucesso()
      } catch (error) {
         onErro()
         console.log('error: ', error)
         this.dispatch('geral/setObjMsgError', error.response.data)
      }
   },
   setConta({commit}, conta) {
      commit('SET_CONTA', conta)
   },
}

const mutations = {

   SET_ALL_LIST(state, allList) {
      var array1 = JSON.parse(sessionStorage.getItem('usuario_contas'))
      allList = allList.filter(item => array1.includes(item.conta_id))
      state.allList = allList
   },
   SET_TODAS_CONTAS(state, allList) {
      state.allList = allList
   },
   SET_FILTRO(state, filtro) {
      state.filtro = filtro
   },
   SET_CONTAS(state, contas) {
      state.all = contas
   },
   SET_CONTA(state, conta) {
      state.conta = conta
   },

}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
