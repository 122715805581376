/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class CentroCusto {
  constructor () {
    this.centro_custo_id = uuidV4()
    this.descricao = ''
    this.ativo = true
    this.empresa_id = sessionStorage.getItem('empresa_id')
  }
}
